import router from "@/router/router";
import { useUserStore } from "@/store/UserStore";
import { usePermissionsStore } from "@/store/PermissionsStore";
import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import { AuthToken, lktApi } from "@lkt/core";
import moment from "moment";
import useNotify from "@/use/useNotify";
import i18n from "@/i18n/i18n";

const whiteList = ["/login", "/404", "/401", "/ui"]; // no redirect whitelist
//@ts-ignore
const t = i18n.global.t;

/**
 * Dispatch user roles in store
 * Generate async routes according to user roles
 * Use a map() to unwrap the ref reactivity
 * @returns {Promise: <Array>}
 */
const dispatchPermissionStoreData = async () => {
  usePermissionsStore().setRoutes();
  const addRoutesPromises = usePermissionsStore().routes.map(
    (route: RouteRecordRaw) => {
      return new Promise<void>((resolve): void => {
        router.addRoute(route);
        resolve();
      });
    },
  );
  await Promise.all(addRoutesPromises);
};

router.afterEach(async (to: any, from, next: any) => {
  usePermissionsStore().setNavActive(router.currentRoute.value.meta.index);
});

// Auto connect du consultant
// enregistrement du token consultant et création de la route sans les paramètres de cnx
const autoconnectConsultant = (to: RouteLocationNormalized) => {
  const authToken = new AuthToken();
  authToken.token = to.query.token as string;
  authToken.expireAt = moment(to.query.expireAt as string);

  lktApi.switchUser(authToken, to.query.switchuser as string);

  const removeTokenQuery = { ...to.query };
  delete removeTokenQuery.token;
  delete removeTokenQuery.expireAt;
  delete removeTokenQuery.switchuser;

  return { ...to, query: removeTokenQuery, replace: true };
};

router.beforeEach(async (to: RouteLocationNormalized, from, next: any) => {
  //useNotify.alertNotify.hasContent = false;

  // auto connect consultant
  if (to.query.token && to.query.expireAt && to.query.switchuser) {
    const nextRoute = autoconnectConsultant(to);
    next(nextRoute);
  }

  // document.title = getPageTitle(to.meta.title);
  const loggedIn = useUserStore().isLogged();

  if (loggedIn) {
    if (useUserStore().hasInfoStored()) {
      useNotify.alertNotify.hasContent = false;
      return next();
    } else {
      try {
        await useUserStore().fetchMe();
        await dispatchPermissionStoreData();
        return next({ ...to, replace: true });
      } catch (error) {
        useNotify.setAlertNotify(
          {
            data: { code: t("notify.title.sessionExpired") },
            message: t("notify.text.ReconnectRequired"),
          },
          'danger',
        );
        return next(`/login?redirect=${to.path}`);
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?redirect=${to.path}`);

      // useNotify.setAlertNotify(
      //   {
      //     data: { code: t("notify.title.yourNotConnected") },
      //     message: t("notify.text.ReconnectRequired"),
      //   },
      //   'danger'
      // );
    }
  }
});

export { dispatchPermissionStoreData };
