import {
  createRouter,
  createWebHistory,
  Router,
} from "vue-router";
import { constantRoutes } from "./routes";

const router: Router = createRouter({
  history: createWebHistory(), //createWebHashHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes: constantRoutes,
});

export function resetRouter() {
  const routes = router.getRoutes();
  routes.forEach((route) => {
    if (route.name && !constantRoutes.find((r) => r.path === route.path)) {
      router.removeRoute(route.name);
    }
  });
}

export default router;
