import { Mission, Cabinet, Candidat, CandidatureContact } from "@lkt/core";
import { Candidature } from "@lkt/core";

export const missionMock: Mission[] = [
  Object.assign(new Mission(), {
    idMission: 30125,
    intitule: "Analyste Financier",
    cabinet: Object.assign(new Cabinet(), {
      code: "AEC",
      smsSignature: "Talents AEC",
      logoLocation: "/logos/aec.png",
    }),
    remunerationMini: 34,
    remunerationMaxi: 38,
    remunerationVariable: null,
    ville: "PARIS 9",
    codePostal: "75009",
    geocoderFound: false,
    dateCreation: new Date("2023-02-20T15:00:32+01:00"),
    adresse: "76 RUE SAINT LAZARE",
    nbCandidatureShared: "5",
    status: "active",
    typeContrat: "cdi",
  }),
  Object.assign(new Mission(), {
    idMission: 45741,
    intitule:
      "Développeur Web - Full Stack, appétence IA, SysAdmin, DevOps, Bûcheron",
    dateCreation: "03/04/24",
    cabinet: Object.assign(new Cabinet(), {
      code: "ASSIST",
      smsSignature: "Assistalents",
      logoLocation: "/logos/assist.png",
    }),
    nbCandidatureShared: "0",
    status: "passive",
    typeContrat: "interim",
  }),
  Object.assign(new Mission(), {
    idMission: 69325,
    intitule: "Chef de Projet",
    dateCreation: "04/05/24",
    cabinet: Object.assign(new Cabinet(), {
      code: "BANK",
      smsSignature: "Talents Banque Assurance",
      logoLocation: "/logos/bank.png",
    }),
    nbCandidatureShared: "0",
    status: "no_way",
    typeContrat: "cdd",
  }),
  Object.assign(new Mission(), {
    idMission: 32141,
    intitule: "Data Scientist",
    dateCreation: "05/06/24",
    cabinet: Object.assign(new Cabinet(), {
      code: "COMPTA",
      smsSignature: "Comptalents",
      logoLocation: "/logos/compta.png",
    }),
    nbCandidatureShared: "14",
    status: "active",
    typeContrat: "moa",
  }),
  Object.assign(new Mission(), {
    idMission: 538745,
    intitule: "Consultant RH",
    dateCreation: "06/07/24",
    cabinet: Object.assign(new Cabinet(), {
      code: "EXECUTIV",
      smsSignature: "Talents Executive",
      logoLocation: "/logos/executive.png",
    }),
    nbCandidatureShared: "0",
    status: "passive",
    typeContrat: "portage",
  }),
  Object.assign(new Mission(), {
    idMission: 29856,
    intitule: "Architecte Cloud",
    dateCreation: "07/08/24",
    cabinet: Object.assign(new Cabinet(), {
      code: "IMMO",
      smsSignature: "Talents Construction",
      logoLocation: "/logos/construction.png",
    }),
    nbCandidatureShared: "0",
    status: "active",
    typeContrat: "cdi",
  }),
  Object.assign(new Mission(), {
    idMission: 36523,
    intitule: "Product Manager",
    dateCreation: "08/09/24",
    cabinet: Object.assign(new Cabinet(), {
      code: "INDUS",
      smsSignature: "Talents Industrie",
      logoLocation: "/logos/logo_indus.jpg",
    }),
    nbCandidatureShared: "0",
    status: "no_way",
    typeContrat: "cdd",
  }),
  Object.assign(new Mission(), {
    idMission: 34125,
    intitule: "Designer UX/UI",
    dateCreation: "09/10/24",
    cabinet: Object.assign(new Cabinet(), {
      code: "IT",
      smsSignature: "Talents IT",
      logoLocation: "/logos/it.png",
    }),
    nbCandidatureShared: "0",
    status: "passive",
    typeContrat: "interim",
  }),
];
