import router from "@/router/router";
import i18n from "@/i18n/i18n";

const t = i18n.global.t;

const alertNotify = reactive<
  {httpStatus: number, hasContent: boolean; type: string, title: string; text: string; redirect: boolean}
>({
  httpStatus: 0,
  hasContent: false,
  type: 'danger',
  title: t("title.popin.unknownError"),
  text : t("sentence.popin.unknownEventCrashTheApp"),
  redirect: false,
});

const UseNotify = () => {
  const formatError = (content) => {
    let title: string;
    let text: string;

    title = content?.data?.code ?? content?.code ?? t("notify.title.unknownError");
    text = content?.data?.error ?? content?.error ?? t("notify.text.unknownEventCrashTheApp");

    return { title, text };
  }

  const errorNotify = (error: string | object, self: any) => {
    const data: {title: string, text: string} = formatError(error);
    return self.$notify({
      title: data.title,
      text: data.text,
      type: "error",
    });
  }

  const succesNotify = (response: { title: string, text: string, }, self: any) => {
    return self.$notify({
      title: response.title,
      text: response.text,
      type: "success",
    });
  };

  const setAlertNotify = (content: any, type: string, redirect?) => {
    // Transfert les données pour la popin
    alertNotify.type = type ?? 'danger';
    alertNotify.hasContent = true;

    // If pour popin erreur
    if (type === 'danger' || !type) {
      // Gère le token invalide
      if (content?.code === 401) {
        router.push({ name: "login" });
      }
      const data: {title: string, text: string} = formatError(content);
      alertNotify.title = data.title;
      alertNotify.text = data.text;

    // If pour popin succes
    } else if (type === 'success') {
      alertNotify.title = content?.title ??  t("notify.title.requestSuccessful");
      alertNotify.text = content?.text ?? t("notify.text.eventCorrectlyWork");
    }

  }
    return {errorNotify, alertNotify, setAlertNotify, succesNotify}
}

const useNotify = UseNotify();

export default useNotify;
