const constantRoutes = [
    {
        path: "/switchconsultant",
        component: () => import("@/pages/LoginPage.vue"),
        hidden: true,
        meta: { layout: "UnauthenticatedLayout" },
        name: "switchconsultant",
    },
  {
    path: "/login",
    component: () => import("@/pages/LoginPage.vue"),
    hidden: true,
    meta: { layout: "UnauthenticatedLayout" },
    name: "login",
  },
  {
    path: "/ui",
    component: () => import("@/pages/Ui.vue"),
    hidden: true,
    meta: { layout: "UnauthenticatedLayout" },
    name: "ui",
  },
  {
    path: "/404",
    component: () => import("@/pages/errors/Page404.vue"),
    hidden: true,
    meta: {
      layout: "UnauthenticatedLayout",
    },
    name: "404",
  },
];

const asyncRoutes = [
  {
    name: "dashboard",
    path: "/",
    meta: {
      layout: "AuthenticatedLayout",
      position: "submenu",
      index: 0,
      icon: "accueil",
    },
    component: () => import("@pages/DashboardPage.vue"),
  },
  // {
  //   path: "/alert",
  //   component: () => import("@pages/AlertPage.vue"),
  //   meta: {
  //     layout: "AuthenticatedLayout",
  //     roles: ["ADMIN", "EDITOR"],
  //     icon: "alertes",
  //     position: "submenu",
  //     index: 5,
  //   },
  //   name: "alert",
  // },
  // {
  //   path: "/cabinet",
  //   component: () => import("@pages/CabinetPage.vue"),
  //   meta: {
  //     layout: "AuthenticatedLayout",
  //     roles: ["ADMIN", "EDITOR"],
  //     icon: "cabinet",
  //     position: "submenu",
  //     index: 4,
  //   },
  //   name: "cabinet",
  // },
  // {
  //   path: "/invoice",
  //   component: () => import("@pages/InvoicePage.vue"),
  //   meta: {
  //     layout: "AuthenticatedLayout",
  //     roles: ["ADMIN", "EDITOR"],
  //     icon: "facture",
  //     position: "submenu",
  //     index: 2,
  //   },
  //   name: "invoice",
  // },
  {
    name: "recruitment",
    path: "/recruitment",
    redirect: { name: "recruitmentList" },
    component: () => import("@/pages/MissionMenu.vue"),
    meta: {
      layout: "AuthenticatedLayout",
      roles: ["ADMIN", "EDITOR"],
      icon: "case",
      position: "submenu",
      index: 1,
      breadcrumb: "recruitment",
    },
    children: [
      {
        name: "recruitmentList",
        path: "",
        component: () => import("@/pages/MissionListPage.vue"),
        meta: {
          layout: "AuthenticatedLayout",
          roles: ["ADMIN", "EDITOR"],
          breadcrumb: "list",
        },
      },
      {
        path: ":idMission",
        component: () => import("@/pages/MissionDetailsPage.vue"),
        meta: {
          layout: "AuthenticatedLayout",
          roles: ["ADMIN", "EDITOR"],
          breadcrumb: "details",
        },
        name: "recruitmentDetails",
      },
      {
        path: "/candidacy/:id",
        component: () =>
          import(
            "@/components/entities/candidacy/CandidacyDetails/CandidacyDetails.vue"
          ),
        meta: {
          layout: "AuthenticatedLayout",
          roles: ["ADMIN", "EDITOR"],
          breadcrumb: "candidacy",
        },
        name: "candidacyDetails",
      },
      {
        path: "/candidacy/:id/print",
        component: () =>
          import(
            "@/components/entities/candidacy/CandidacyDetails/PrintCandidacyDetails.vue"
            ),
        meta: {
          layout: "PrintCvLayout",
          roles: ["ADMIN", "EDITOR"],
          breadcrumb: "candidacy",
        },
        name: "printCandidacyDetails",
      },
    ],
  },
  // {
  //   path: "/search",
  //   component: () => import("@pages/SearchPage.vue"),
  //   meta: {
  //     layout: "AuthenticatedLayout",
  //     roles: ["EDITOR"],
  //     icon: "search",
  //     position: "submenu",
  //     index: 6,
  //   },
  //   name: "search",
  // },
  // {
  //   path: "/tracking",
  //   component: () => import("@pages/TrackingPage.vue"),
  //   meta: {
  //     layout: "AuthenticatedLayout",
  //     roles: ["ADMIN", "EDITOR"],
  //     icon: "suivi",
  //     position: "submenu",
  //     index: 3,
  //   },
  //   name: "tracking",
  // },

  // Dynamic Import

  // This route should be the last one on the list
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
    hidden: true,
    name: "404catch",
  },
];

export { asyncRoutes, constantRoutes };
