<script lang="ts">
import { Vue, Component } from "vue-facing-decorator";
import { Notifications } from "@kyvg/vue3-notification";

@Component({
  components: {
    Notifications,
  },
})
export default class Notify extends Vue {
}
</script>

<template>
  <notifications :duration="50000" :max="6" position="bottom left" class="lkt-notify-popup" />
</template>

<style lang="scss">
@import "@/styles/_variables.scss";

.lkt-notify-popup > div {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.lkt-notify-popup {
  margin: 0 0 15px 15px;
  padding: 0;

  .vue-notification-wrapper {
    width: fit-content;
    overflow: unset;
  }

  .vue-notification-template {
    box-shadow: 0px 3px 20px #0000001A;
    overflow: hidden;
    border-radius: 20px;
    width: 390px;
  }

  .vue-notification {
    // styling
    margin: 0;
    padding: 10px 20px;
    font-size: 15px;
    color: $gray-400;
    background: white!important;

    .notification-title {
      color: $secondary;
      text-transform: capitalize;
      font-size: 16px;
      margin-bottom: 8px;
    }

    // default (lkt color)
    border-left: 20px solid $primary;

    // types (green, amber, red)
    &.success {
      border-left-color: #42a85f;
    }

    &.warn {
      border-left-color: #f48a06;
    }

    &.error {
      border-left-color: #ec473c;
    }
  }
}
</style>
