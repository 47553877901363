<script setup lang="ts">
import { provide, shallowRef } from "vue";
import { RouteLocationNormalized } from "vue-router";
import router from "./router/router";
import AuthenticatedLayout from "@/components/layouts/AuthenticatedLayout.vue";
import UnauthenticatedLayout from "@/components/layouts/UnauthenticatedLayout.vue";

const layouts = {
  AuthenticatedLayout,
  UnauthenticatedLayout,
};
// Le type est composant vue (layouts) ou null
const layout = shallowRef<null | (typeof layouts)[keyof typeof layouts]>(null);

// Gestion du layout après chaque changement de route
router.afterEach((to: RouteLocationNormalized) => {
  const layoutKey = to.meta.layout as keyof typeof layouts;
  layout.value = layouts[layoutKey] || null;
});
provide("app:layout", layout);
</script>

<template>
    <component v-if="layout" :is="layout"></component>
    <router-view v-else />
</template>

<style lang="scss">
</style>
