// i18n.ts
import { createI18n } from "vue-i18n";
import { fr } from "@/i18n/locales/fr-FR"; // Import translations
import { en } from "@/i18n/locales/en-US";
import { transformFlatKeysToNested } from "@/i18n/utils/transform"; // Import transformation function

// Transform the flat key translations
const messages = {
  "fr-FR": transformFlatKeysToNested(fr),
  "en-US": transformFlatKeysToNested(en),
};

export default createI18n({
  locale: "fr-FR",
  fallbackLocale: "en-US",
  messages,
});
