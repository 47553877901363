export const fr = {
  "advantageBanner.success": "Au succès et sans acompte",
  "advantageBanner.invoice": "Facturation à la prise de poste",
  "advantageBanner.tracking": "Suivi d'intégration",
  "advantageBanner.guaranty":
    "Garantie étendue à l'ensemble de la période d'essai",

  "availability.immediate": "Disponible immédiatement",
  "availability.latency": "Préavis",

  "breadcrumb.backTo": "retour à",
  "breadcrumb.recruitment": "mes recrutements",

  "button.call": "Appeller",
  "button.chatTo": "Chatter avec",
  "button.contact": "Contacter",
  "button.WriteMailTo": "Ecrire un mail à",
  "button.connect": "Me connecter",
  "button.disconnect": "Déconnectez-vous",
  "button.showMore": "Voir plus",
  "button.showLess": "Voir moins",
  "button.sendMail": "Envoyer un e-mail",
  "button.beRecalled": "Être rappelé(e)",
  "button.discuss": "Discuter",
  "button.retain": "Retenir",
  "button.reject": "Écarter",
  "button.cancel": "annuler",
  "button.validate": "valider",
  "button.download": "Télécharger",
  "button.print": "Imprimer",
  "button.backToHome": "Revenir à l'accueil",
  "button.reloadPage": "Rafraichir la page",
  "button.closePopin": "Fermer la boite contextuelle",

  "candidacy.new": "new !",
  "candidacy.retained": "retenue",
  "candidacy.pending": "en cours de décision",
  "candidacy.not-retained": "écarté",
  "candidacy.disabled": "non disponible",
  "candidacy.alreadyRetained": "Candidature déjà retenue",
  "candidacy.alreadyRejected": "Candidature déjà rejetée",
  "candidacy.yourInterested": "Vous êtes intéréssé(e) par le candidat",
  "candidacy.yourAvailabilityForMeet":
    "Quelles sont vos disponibilités pour un éventuel entretien avec le candidat ?",
  "candidacy.candidateGoingToBeRejected": "Le candidat va être écraté",
  "candidacy.whatIsTheReasonToReject": "Quelle est la raison de votre refus ?",
  "candidacy.noteFromYourConsultant": "Les notes de votre consultant",
  "candidacy.informations.contactDetails": "Coordonnées",
  "candidacy.informations.professionalCareer": "Parcours Professionnel",
  "candidacy.informations.trainingAndSkills": "Formation & Compétences",
  "candidacy.informations.trainingLevel": "Niveau de formation",
  "candidacy.informations.languageLevel": "Niveau de langue",
  "candidacy.informations.motivation": "Motivation",
  "candidacy.informations.references": "Références",
  "candidacy.informations.availabilities": "Disponibilité",
  "candidacy.informations.testDone": "Test(s) effectué(s)",

  "chat.action.closeChat": "Fermer le chat",

  "currency.thousandsCurrency": "K€",

  "dashboard.welcomeToyourCustomerSpace":
    "bienvenue sur \n votre espace client",

  "error.fieldRequired": "Le champs est requis",
  "error.invalidMail": "L'email saisi n'est pas valide",

  "login.title": "Connectez-vous à votre espace Linking Talents",
  "login.subtitle":
    "Afin d’assurer la confidentialité des informations, merci de bien vouloir nous indiquer votre adresse mail.",

  "footer.1percent":
    "Nous reversons 1% du chiffre d'affaires annuel de notre holding à des associations environnementales.",
  "footer.allRightsReserved": "tous droits réservés",

  "message.current": "actuelle",

  "name.dashboard": "tableau de bord",
  "name.recruitment": "recrutements",
  "name.invoice": "factures",
  "name.tracking": "suivis",
  "name.cabinet": "cabinets",
  "name.alert": "alertes",
  "name.search": "recherches",

  "notify.title.callBackSuccess": "Notification envoyée",
  "notify.title.yourNotConnected": "Vous n'êtes pas identifié(e)",
  "notify.title.sessionExpired": "Session expirée",
  "notify.title.sessionCorrupted": "Session erronée",
  "notify.title.unknownError": "Erreur inconnue",
  "notify.title.requestSuccessful": "Opération réussie",
  "notify.text.unknownEventCrashTheApp": "Un évenement inconnue à fait planter l'app",
  "notify.text.eventCorrectlyWork": "L'interaction à correctement fonctionnée",
  "notify.text.callBackSuccess": "Vous serez rapidement contacté(e).",
  "notify.text.ReconnectRequired": "Merci de vous reconnecter",

  "sentence.interestedInOurCabinets":
    "Par rapport à vos besoins, seriez-vous intéressé par nos cabinets ?",
  "sentence.sloganPosition": "Le recrutement spécialisé",
  "sentence.slogan": "Nous proposons vous choisissez.",
  "sentence.cvReceived": "CV reçus",
  "sentence.showCard": "voir la fiche",
  "sentence.someQuestion": "une question ?",
  "sentence.getInTouchWith": "vous pouvez prendre contact avec",
  "sentence.navigationTipsTitle": "Astuce de navigation",
  "sentence.navigationTipsSwipe":
    "Naviguez entre les fiches candidat d'un simple swipe !",
  "sentence.notSpecified": "Non renseigné",
  "sentence.connectionFailedMessage": "Identifiant de connexion invalide",
  "sentence.clickToDownload": "Cliquer pour télécharger",

  "title.ourAdvantages": "nos avantages",
  "title.ourContactDetails": "Nos coordonnées",
  "title.followUs": "Suivez-nous",
  "title.myMissions": "Mes recrutements",
  "title.ourSuggestions": "Nos Suggestions",


  "word.adress": "adresse",
  "word.tel": "tél",
  "word.mail": "mail",
  "word.summary": "résumé",
  "word.recruitment": "recrutement",
  "word.disconnect": "déconnexion",
  "word.availabilities": "disponibilités",
  "word.explanations": "explications",
  "word.presentation": "présentation",
  "word.experience": "experience",
  "word.management": "encadrement",
  "word.software": "logiciel",
  "word.yes": "oui",
  "word.no": "non",
  "word.error": "erreur",
};
