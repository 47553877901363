<script lang="ts">
import { Vue, Component } from "vue-facing-decorator";
import { usePermissionsStore } from "@/store/PermissionsStore";
import { useUserStore } from "@/store/UserStore";
import useNotify from "@/use/useNotify";
import useChat from "@/use/useChats";

@Component({
  methods: { useUserStore, usePermissionsStore },
})
export default class SelectRoles extends Vue {
  selectedRole: string = "ADMIN";
  isVisible: boolean = true;
  langs: string[] = ["fr-FR", "en-US"];
  usePermissionsStore = usePermissionsStore;

  mounted() {
    this.chatState
  }
  updateRole() {
    usePermissionsStore().setRoles([this.selectedRole]);
  }

  toggle() {
    this.isVisible = !this.isVisible;
  }

  setAlert(type) {
    useNotify.setAlertNotify(null, type);
  }

  chatData: any;
  get chatState() {
    this.chatData = useChat.chatState;
    return useChat.chatState.state;
  }
}
</script>

<template>
  <div
    class="z-3 shadow bg-white p-4 select-roles"
    :style="{ right: isVisible ? '-190px' : '0' }"
  >
    <button
      @click="toggle"
      class="toggle-select-roles btn btn-primary"
    ></button>
    <div class="lkt-form-group">
      <label for="select-2">Changer de rôle</label>
      <select
        v-model="selectedRole"
        @change="updateRole"
        id="select-2"
        required
      >
        <option value="ADMIN">ADMIN</option>
        <option value="EDITOR">EDITOR</option>
      </select>
    </div>
    <p class="text-sm text-gray-400 mt-3">
      Rôles : {{ usePermissionsStore().roles }}
    </p>
    <div class="locale-changer my-3">
      <select v-model="$i18n.locale">
        <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
          {{ lang }}
        </option>
      </select>
      Lang : {{ $i18n.locale }}
    </div>
    <button @click="setAlert('danger')" class="btn btn-secondary">Alert Erreur</button>
    <button @click="setAlert('success')" class="btn btn-secondary">Alert Succes</button>
    <div>
      <h6 class="mt-2">Chat Store :</h6>
      <p>{{chatData}}</p>
    </div>
  </div>
</template>

<style scoped>
.select-roles {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 210px;
  right: 0;
  transition: all 0.2s ease-in-out;
}

.toggle-select-roles {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 4px;
  height: 100px;
  width: 5px;
  padding: 0;
}
.toggle-select-roles:hover {
  background-color: #9a0e35;
}
</style>
