import { defineStore } from "pinia";
import { Candidature, Mission, Cabinet } from "@lkt/core";
import { candidacyMock } from "@/mock/candidacyMock";
import { missionMock } from "@/mock/missionMock";
import { cabinetMock } from "@/mock/cabinetMock";
import { cvMock } from "@/mock/cvMock";
import { ChatMessage } from "@lkt/core";
import dayjs from "dayjs";
import { chatMock } from "@/mock/chatMock";
import { Chat } from "@/entities/Chat";

interface MockStoreState {
  candidatureMock: Candidature[];
  candidatCount: number;
  missionMock: Mission[];
  missionCount: number;
  cabinetMock: Cabinet[];
  cabinetCount: number;
  cvMock: any;
  cvCount: number;
  chatMock: Chat[];
  chatCount: number;
}
export const useMockStore = defineStore("MockStore", {
  state: (): MockStoreState => {
    return {
      candidatureMock: [] as Candidature[],
      candidatCount: 0 as number,
      missionMock: [] as Mission[],
      missionCount: 0 as number,
      cabinetMock: [] as Cabinet[],
      cabinetCount: 0 as number,
      cvMock: [] as any,
      cvCount: 0 as number,
      chatMock: [] as Chat[],
      chatCount: 0 as number,
    };
  },
  actions: {
    setCandidatures() {
      this.$patch((state) => {
        state.candidatureMock = candidacyMock;
        // @ts-ignore
        state.candidatCount++;
      });
    },
    patchCandidacy(idCandidacy: number, data: any): Candidature {
      let response = {};
      this.$patch((state) => {
        let candidacy = state.candidatureMock.find(
          (candidacy) => candidacy.idCandidature == idCandidacy,
        );
        if (candidacy) {
          response = this.deepMerge(candidacy, data);
        }
      });
      return response as Candidature;
    },
    async updateReadAt(idCandidacy): Promise<string> {
      return new Promise((resolve, reject) => {
        if (
          JSON.stringify(
            this.patchCandidacy(idCandidacy, {
              currentContact: {
                readAt: dayjs(Date.now()).format("YYYY-MM-DDTHH:mm:ss"),
              },
            }),
          ) != JSON.stringify({})
        ) {
          resolve("Mise à jour réussie");
        } else {
          reject("Mise à jour échouée");
        }
      });
    },

    setMissions() {
      this.$patch((state) => {
        state.missionCount++;
        state.missionMock = missionMock;
      });
    },
    setCabinet() {
      this.$patch((state) => {
        state.cabinetCount++;
        state.cabinetMock = cabinetMock;
      });
    },
    setCv() {
      this.$patch((state) => {
        state.cvCount++;
        state.cvMock = cvMock;
      });
    },
    setChat() {
      this.$patch((state) => {
        state.chatCount++;
        state.chatMock = chatMock;
      });
    },
    deepMerge(target: any, source: any) {
      for (const key in source) {
        if (
          source[key] &&
          typeof source[key] === "object" &&
          !Array.isArray(source[key]) // Si la valeur est un objet la fonction se rappelle
        ) {
          if (!target[key]) {
            target[key] = {};
          }
          this.deepMerge(target[key], source[key]);
        } else {
          target[key] = source[key]; // sinon on mets à jour les valeurs
        }
      }
      return target;
    },
  },
  persist: true,
});
