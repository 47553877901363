<script lang="ts">
import { Vue, Prop, Component } from "vue-facing-decorator";
import { useUserStore } from "@/store/UserStore";

import router from "@/router/router";

@Component({
  components: {
    Image: () => import("@/components/images/Image.vue"),
    SelectLang: () => import("./SelectLang.vue"),
  },
  methods: { useUserStore },
})
export default class Navbar extends Vue {
  @Prop({ required: false, default: true, type: Boolean })
  readonly isAuthenticated!: boolean;

  useUserStore = useUserStore;
  //private selectedRole = ref<string>("ADMIN");
  isLogged = useUserStore().isLogged() ?? false;

  logout() {
    useUserStore().logout();
    router.push(`/login?redirect=/`);
  }
}
</script>

<template>
  <nav class="page-header p-2 py-4 shadow-mobile bg-white">
    <div class="container-xl p-0">
      <div class="row mx-auto align-items-center">
        <div class="w-fit">
          <router-link :to="'/'">
            <Image :class="'lkt-logo'" :src="'lkt-logo.png'" />
          </router-link>
        </div>

        <!-- Authenticated section -->
        <div
          v-if="isLogged"
          class="col justify-content-end d-flex text-secondary"
        >
          <div class="d-none d-md-flex align-items-center justify-content-end">
            <p class="text-sm border-end border-secondary px-3 m-0">
              {{ $t("sentence.sloganPosition") }}
            </p>
            <p class="px-3 m-0 text-md font-semibold">{{ useUserStore()?.username ? useUserStore()?.username : 'Nom Prénom' }}</p>
            <BPopover
              :click="true"
              :close-on-hide="true"
              :delay="{ show: 0, hide: 0 }"
            >
              <template #target>
                <p class="text-primary m-0 text-sm fst-italic cursor-pointer">
                  {{ $t("button.disconnect") }}
                </p>
              </template>
              <div>
                <BButton
                  @click="logout"
                  class="btn btn-primary mx-auto py-2 px-3 text-sm"
                >{{ $t("word.disconnect") }}</BButton
                >
              </div>
            </BPopover>
          </div>
          <SelectLang />
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
nav img {
  height: 100%;
  max-height: 45px;
  width: auto;
}

@media (max-width: 768px) {
  nav img {
    max-height: 35px;
  }
}
</style>
