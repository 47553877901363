<script lang="ts">
import { Vue, Component } from "vue-facing-decorator";
import { Notifications } from "@kyvg/vue3-notification";
import useNotify from "@/use/useNotify";

@Component({
  components: {
    Page404: () => import("@pages/errors/Page404.vue"),
    PopInError: () => import("@/components/generic/popup/PopInError.vue"),
    Notifications,
    Navbar: () => import("@/components/layouts/navigation/Navbar.vue"),
    NavbarSub: () => import("@/components/layouts/navigation/NavbarSub.vue"),
    Footer: () => import("@/components/layouts/navigation/Footer.vue"),
    SelectRoles: () => import("@/components/tmp/selectRoles.vue"),
    MobileFooter: () =>
      import("@/components/layouts/navigation/MobileFooter.vue"),
    Notify: () => import("@/components/generic/notify/Notify.vue"),
  },
})
export default class AuthenticatedLayout extends Vue {
  windowTop: number = 0;
  hideNav: boolean = false;
  mobileMenuOpen: boolean = false;
  popinErrorState: boolean = true;
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.checkScreenWidth);
  }
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.checkScreenWidth);
  }

  onScroll() {
    if (window.top && !this.mobileMenuOpen) {
      this.hideNav =
        window.top.scrollY > 93 && window.top.scrollY >= this.windowTop;
      this.windowTop = window.top.scrollY;
    }
  }

  onToggleMenuMobile(expand: boolean) {
    this.mobileMenuOpen = expand;
  }

  checkScreenWidth() {
    // Automatic close the mobile menu if screen larger than 768
    if (window.innerWidth > 768) {
      this.mobileMenuOpen = false;
    }
  }

  popinErrorToggleState(value) {
    this.popinErrorState = value;
    useNotify.alertNotify.hasContent = false;
  }

  get alert() {
    if(useNotify.alertNotify.hasContent) {
      this.popinErrorState = true;
    }
    return useNotify.alertNotify;
  }
}
</script>
<template>
    <div :class="`navbar-wrap ${hideNav ? 'hide-nav' : ''}`">
      <Navbar></Navbar>
      <NavbarSub
        @toggle-mobile-menu="onToggleMenuMobile"
        :mobileMenuOpen
        :background="windowTop > 25 ? 'bg-gray-100' : 'bg-transparent'"
      ></NavbarSub>
    </div>
    <div class="content-wrap">
      <Page404 v-if="alert.hasContent && alert.redirect" :alertContent="alert"></Page404>
      <router-view v-else />
    </div>
    <SelectRoles />
    <Footer :class="'d-none d-md-flex'"></Footer>
    <MobileFooter :class="'d-md-none'"></MobileFooter>
  <Notify />
  <PopInError
    @toggle-modal-state="popinErrorToggleState"
    :buttonRefresh="alert.type === 'danger'"
    :buttonHome="alert.type === 'danger'"
    :buttonClose="alert.type === 'success'"
    :state="alert.hasContent && popinErrorState">
  </PopInError>
</template>

<style>
</style>
