import { useMockStore } from "@/store/Mock/MockStore";
import { ChatMessage } from "@lkt/core";
import { chatsStore } from "@/store/ChatsStore";
import { lktApi } from "@lkt/core";
import { Chat } from "@/entities/Chat";
import { Consultant } from "../../../payjob/lktcore";

const chatState = reactive<
  {state: boolean, name: string | null, mail: string | null, telNumber: string | null}
>({
  state: false,
  name: null,
  mail: null,
  telNumber: null,
});

const UseChats = () => {
  const fetchMessages = (): Promise<Chat[]> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const messages: Chat[] | undefined = useMockStore().$state.chatMock;
        if (messages) {
          chatsStore().setMessages(messages);
          resolve(messages);
        } else {
          reject("Aucuns messages trouvés");
        }
      }, 200);
    });
  };

  const sendChat = (chat): Promise<any> => {
    return new Promise((resolve, reject) => {
      const response = lktApi.post('sendChat', {
        chatMessage: chat as ChatMessage,
      });

      if (response) {
        resolve(response);
      } else {
        reject("Envoi du message a échoué");
      }
    });
  };

  const toggleChat = (value): void => {
    chatState.state = value;
  }

const setName = (name): void => {
    chatState.name = name;
  }
  const setMail = (mail): void => {
    chatState.mail = mail;
  }
  const setTelNumber = (telNumber): void => {
    chatState.telNumber = telNumber;
  }

  const setChatData = (consultant: Consultant) => {
      setName(consultant?.nom);
      setMail(consultant?.email);
      setTelNumber(
        consultant?.mobileRaw ? consultant?.mobileRaw :
          consultant?.telephoneRaw ? consultant?.telephoneRaw : null
      );

  }

  return { fetchMessages, sendChat, chatState, toggleChat, setName, setMail, setTelNumber, setChatData };
};
const useChats = UseChats();
export default useChats;
