<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-facing-decorator";
import useNotify from "@/use/useNotify";
import router from "@/router/router";

@Component({
  components: {
    PopIn: () => import("@/components/generic/popup/PopIn.vue")
  }
})
export default class PopOver extends Vue {
  @Prop({required: true, type: Boolean})
  state: boolean = false;
  @Prop({required: false, type: Boolean})
  buttonRefresh?: boolean = true;
  @Prop({required: false, type: Boolean})
  buttonHome?: boolean = true;
  @Prop({required: false, type: Boolean})
  buttonClose?: boolean = true;
  reloadPage() {
    window.location.reload();
  }

  @Emit("toggle-modal-state")
  toggleModalState() {
    return false;
  }

  redirectHome() {
    this.alert.hasContent = false;
    router.push({ name: "dashboard" });
  }
  get alert() {
    return useNotify.alertNotify;
  }

  getIconClassByAlertType(type) {
    if(type === 'danger') {
      return 'bi-exclamation-triangle text-primary';
    } else if(type === 'success') {
      return 'bi-check2-circle text-success';
    } else {
      return 'bi-chat-left-dots text-gray-400';
    }
  }
}
</script>

<template>
  <PopIn
    @toggle-modal-state="toggleModalState"
    class="col-6 col-md-12"
    :popinClass="`popinError ${state ? 'popinError--open' : ''}`"
    :modalState="state"
    :popinWidth="'400'"
  >
    <div
      class="mx-auto text-center bg-gray-200 icon-danger-wrapper rounded-circle"
    >
      <i :class="`
          p-2 text-5xl tutuu bi
          ${getIconClassByAlertType(alert.type)}
     `"></i>
    </div>
    <h5 class="text-center mt-3">{{ alert.title }}</h5>
    <p v-if="alert.text" class="text-center">{{ alert.text }}</p>
    <div class="d-flex mt-6 justify-content-between">
      <button v-if="buttonHome" @click="redirectHome" class="btn btn-secondary">{{$t("button.backToHome")}}</button>
      <button v-if="buttonRefresh" @click="reloadPage" class="btn btn-outline-secondary">
        {{$t("button.reloadPage")}}
      </button>
      <button v-if="buttonClose" @click="toggleModalState" class="btn btn-secondary mx-auto">
        {{$t("button.closePopin")}}
      </button>
    </div>
  </PopIn>
</template>

<style lang="scss">
@import "@/styles/_variables.scss";

body:has(.popinError--open){
  overflow-y: hidden;
}

.icon-danger-wrapper {
  height: 70px;
  width: 70px;
  border-radius: 50px;
  line-height: 70px;

  i {
    line-height: 70px;
  }
}
</style>
