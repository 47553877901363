import { defineStore } from "pinia";
import { FormLoginEty } from "@/typing/formLoginEty";
import { lktApi } from "@lkt/core";
import { Contact } from "@lkt/core";
import { usePermissionsStore } from "@/store/PermissionsStore";
import { resetRouter } from "@/router/router";

export const useUserStore = defineStore("userStore", {
  // other options...

  state: () => {
    return {
      id: 0,
      username: "",
      email: "",
      language: "",
      avatar: "",
    };
  },
  actions: {
    // User Token exists, but no info in store (refresh browser page) => reload user from api
    async fetchMe() {
      const contact = await lktApi.get<Contact>("me");

      this.$patch((state) => {
        state.id = contact.idContact;
        state.username = contact.nomPrenom;
        state.email = contact.email;
        state.language = 'fr';
      });

      const permissionsStore = usePermissionsStore();
      const roles = ['EDITOR'];
      permissionsStore.setRoles(roles);
    },

    isLogged() {
      return lktApi.isLoggedIn();
    },

    hasInfoStored() {
      return !!this.$state.id;
    },

    async hasTokenValid(){
      try {
        await this.fetchMe();
        return true;
      }catch(err) {
        console.log(err);
        return false;
      }
    },

    async login(payload: FormLoginEty) {
      await lktApi.login({
        username: payload.login as string,
        password: payload.password as string,
      });
      await this.fetchMe();
    },

    logout() {
      const permissionsStore = usePermissionsStore();
      permissionsStore.clearRoutes();
      permissionsStore.clearRoles();
      this.$reset();
      lktApi.logout();
      resetRouter();
    },
  },
});
