<script lang="ts">
import { Vue, Component } from "vue-facing-decorator";
import useNotify from "@/use/useNotify";

@Component({
  components: {
    Notify: () => import("@/components/generic/notify/Notify.vue"),
    Navbar: () => import("@/components/layouts/navigation/Navbar.vue"),
    Footer: () => import("@/components/layouts/navigation/Footer.vue"),
    MobileFooter: () =>
      import("@/components/layouts/navigation/MobileFooter.vue"),
    PopInError: () => import("@/components/generic/popup/PopInError.vue"),
  },
})
export default class AuthenticatedLayout extends Vue {
  windowTop: number = 0;
  hideNav: boolean = false;
  popinErrorState: boolean = true;
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  }
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  }

  popinErrorToggleState(value) {
    this.popinErrorState = value;
  }

  get alert() {
    if(useNotify.alertNotify.hasContent) {
      this.popinErrorState = true;
    }
    return useNotify.alertNotify;
  }

  onScroll() {
      if( window.top ) {
          this.hideNav =
              window.top.scrollY > 92 && window.top.scrollY >= this.windowTop;
          this.windowTop = window.top.scrollY;
      }
  }
}
</script>

<template>
  <div :class="`navbar-wrap ${hideNav ? 'hide-nav' : ''}`">
    <Navbar></Navbar>
  </div>
  <div class="content-wrap">
    <router-view />
  </div>
  <Footer :class="'d-none d-md-flex'"></Footer>
  <MobileFooter :class="'d-md-none'"></MobileFooter>
  <Notify />
  <PopInError @toggle-modal-state="popinErrorToggleState" :buttonRefresh="true" :buttonHome="true" :state="alert.hasContent && popinErrorState"> </PopInError>
</template>

<style lang="scss">
@import "@/styles/_variables.scss";

body {
  padding-top: 93px;
}
.navbar-wrap {
  height: 93px;
}

@media (max-width: 1199px) {
  body {
    padding-top: 93px;
  }

  .navbar-wrap {
    height: 93px;
  }
}

@media (max-width: 992px) {
  body {
    padding-top: 83px;
  }

  .navbar-wrap {
    height: 83px;
  }
}
</style>
