import {enumReadableValue, useTranslator} from "@lkt/core";
import {Plugin} from "vue";


export default {
    install: (app, options) => {
        app.config.globalProperties.$enumReadableValue = enumReadableValue;
        app.config.globalProperties.$enumTrans = useTranslator.enumTrans;
    }
} as Plugin
