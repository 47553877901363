<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";
import { usePermissionsStore } from "@/store/PermissionsStore";

@Component({
  methods: { usePermissionsStore },
})
export default class Image extends Vue {
  @Prop()
  readonly src!: string;
  readonly classList!: string;

  getImageUrl() {
    return new URL(`../../assets/${this.src}`, import.meta.url).href;
  }
}
</script>

<template>
  <img :class="classList" :src="getImageUrl()" alt="" />
</template>

<style scoped>
img {
  width: auto;
}
</style>
