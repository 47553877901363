import dayjs from "dayjs";
import { ChatMessage } from "@lkt/core";
import { Chat } from "@/entities/Chat";



export const chatMock: Chat[] = [
  {
    sender: true,
    read: null,
    date: dayjs("2024-04-13T15:25:00"),
    content:
      "Bonjour, je souhaiterais des infos complémentaires pour le candidat 75845.",
  },
  {
    sender: false,
    read: false,
    date: dayjs("2024-04-13T15:27:00"),
    content:
      "Bonjour ! Mais bien sûr ! 75845... ah, notre 'petit génie' en herbe. Que voulez-vous savoir ?",
  },
  {
    sender: true,
    read: null,
    date: dayjs("2024-04-13T15:28:00"),
    content:
      "On m’a dit qu’il est 'polyglotte', mais... en quel sens exactement ? Il connaît plusieurs langages de programmation, ou il parle couramment sept langues ?",
  },
  {
    sender: false,
    read: false,
    date: dayjs("2024-04-13T15:29:00"),
    content:
      "Ah, 'polyglotte' ! Disons qu’il maîtrise JavaScript, Python, C++, et… le langage des bugs bien sûr. Côté langues humaines, en revanche... c'est une autre histoire !",
  },
  {
    sender: true,
    read: null,
    date: dayjs("2024-04-13T15:30:00"),
    content:
      "D'accord... donc pas de risque de le voir discuter en mandarin avec nos clients chinois ?",
  },
  {
    sender: false,
    read: false,
    date: dayjs("2024-04-13T15:31:00"),
    content:
      "Seulement si 'console.log' est une salutation en mandarin ! Il est bien plus à l'aise avec des accolades qu’avec des salutations internationales, disons.",
  },
  {
    sender: true,
    read: null,
    date: dayjs("2024-04-13T15:32:00"),
    content: "Bon... Et côté compétences techniques, il fait du Node.js, non ?",
  },
  {
    sender: false,
    read: false,
    date: dayjs("2024-04-13T15:33:00"),
    content:
      "Node.js ? Absolument ! Et React, Angular... tout ce qu'il faut. Enfin, sauf ce qui est 'intuitif', bien sûr. Disons qu’il a une relation tumultueuse avec les frameworks front-end.",
  },
  {
    sender: true,
    read: null,
    date: dayjs("2024-04-13T15:34:00"),
    content: "Intéressant ! Alors il doit dormir aussi peu qu'il code, non ?",
  },
  {
    sender: false,
    read: false,
    date: dayjs("2024-04-13T15:35:00"),
    content:
      "S'il dort ? Eh bien, on pense qu'il se recharge en mettant sa chaise en 'standby'. L’inspiration peut frapper à toute heure !",
  },
  {
    sender: true,
    read: null,
    date: dayjs("2024-04-13T15:36:00"),
    content:
      "Tant qu'il ne se met pas à coder en COBOL... Vous ne me dites pas qu’il touche à ça aussi ?",
  },
  {
    sender: false,
    read: false,
    date: dayjs("2024-04-13T15:37:00"),
    content:
      "Oh, mais il a tenté, figurez-vous ! Nostalgie des années 60, peut-être. On dit même qu’il a ressuscité quelques lignes de BASIC pour le fun.",
  },
  {
    sender: true,
    read: null,
    date: dayjs("2024-04-13T15:38:00"),
    content:
      "Un vrai nostalgique du clavier, à ce que je vois ! Très bien, envoyez-moi ses coordonnées... Je sens qu'on va avoir des discussions hautes en couleur.",
  },
  {
    sender: false,
    read: false,
    date: dayjs("2024-04-13T15:39:00"),
    content:
      "Avec plaisir ! Préparez-vous pour un voyage technologique dans le temps !",
  },
];
