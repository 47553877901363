import dayjs from "dayjs";
import "dayjs/locale/fr";
import localizedFormat from "dayjs/plugin/localizedFormat";

// Exemple de filtre pour formater une date
export function formatDate(date: string | Date, format: string): string {
  dayjs.extend(localizedFormat);
  return dayjs(date).format(format);
}

// Exemple de filtre pour calculer la différence entre deux dates
export function dateDiff(
  startDate: string | Date,
  endDate: string | Date,
): number {
  return dayjs(endDate).diff(dayjs(startDate), "days");
}
