import { Cabinet } from "@lkt/core";

export const cabinetMock: Cabinet[] = [
  Object.assign(new Cabinet(), {
    code: "ADV",
    smsSignature: "Talents ADV et Supply",
    logoLocation: "/logos/adv.png",
  }),
  Object.assign(new Cabinet(), {
    code: "AEC",
    smsSignature: "Talents AEC (Audit Expertise Comptable)",
    logoLocation: "/logos/aec.png",
  }),
  Object.assign(new Cabinet(), {
    code: "ASSIST",
    smsSignature: "Assistalents",
    logoLocation: "/logos/assist.png",
  }),
  Object.assign(new Cabinet(), {
    code: "BANK",
    smsSignature: "Banque Assurance",
    logoLocation: "/logos/bank.png",
  }),
  Object.assign(new Cabinet(), {
    code: "COMPTA",
    smsSignature:
      "Comptalents (spécialiste du recrutement des professions comptables)",
    logoLocation: "/logos/compta.png",
  }),
  Object.assign(new Cabinet(), {
    code: "EXECUTIVE",
    smsSignature:
      "Talents Executive (Expert fonctions support - Middle & Top Management)",
    logoLocation: "/logos/executive.png",
  }),
  Object.assign(new Cabinet(), {
    code: "IMMO",
    smsSignature: "Talents Construction",
    logoLocation: "/logos/construction.png",
  }),
  Object.assign(new Cabinet(), {
    code: "INDUS",
    smsSignature: "IndusSearch",
    logoLocation: "/logos/logo_indus.jpg",
  }),
  Object.assign(new Cabinet(), {
    code: "IT",
    smsSignature: "Talents IT (cabinet de recrutement IT & Digital)",
    logoLocation: "/logos/it.png",
  }),
];
