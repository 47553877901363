<script lang="ts">
import { Vue, Component, Emit, Prop } from "vue-facing-decorator";
import { usePermissionsStore } from "@/store/PermissionsStore";
@Component({
  methods: { usePermissionsStore },
})
export default class NavbarSub extends Vue {
  @Prop({ required: false, type: Boolean })
  readonly mobileMenuOpen: boolean = false;
  @Prop({ required: false, type: String})
  readonly background: string = '';
  usePermissionsStore = usePermissionsStore;

  @Emit("toggle-mobile-menu")
  toggleMenuMobile() {
    return !this.mobileMenuOpen;
  }
  setActiveLink(index) {
    usePermissionsStore().setNavActive(index);
  }
}
</script>

<template>
  <div :class="`${background} navbar-sub-wrapper`">
    <div class="container-xl position-relative ">
      <div class="position-absolute w-100 left-0 top-0 py-md-2 navbar-sub">
        <ul class="w-50 d-none d-md-flex mb-0 p-0">
          <li
            v-for="(link, index) in usePermissionsStore().subMenuRoutes"
            class="border-bottom border-gray-300 position-relative"
          >
            <router-link
              @click="setActiveLink(index)"
              :class="'d-flex justify-content-center nav-link text-nowrap w-100 text-center align-middle mb-0 py-2 h-100 justify-content-center text-secondary text-capitalize border-b nav-link position-relative'"
              :to="link.path"
            >
            <span
              :style="[
                0 === index
                  ? `left: ${usePermissionsStore().navActive}00%`
                  : '',
              ]"
              :class="[0 === index ? 'active-link' : '']"
            ></span>
              <div class="d-flex">
                <i
                  :class="
                  'align-self-center d-block d-md-inline-block mx-auto text-secondary icon-w-md-4 icon-h-md-4 icon-w-xl-6 icon-h-xl-6 me-md-2 lkt-icon-' +
                  [link.meta.icon ? link.meta.icon : '']
                "
                ></i>
                <span>{{ $t("name." + link.name) }}</span>
              </div>
            </router-link>
          </li>
        </ul>
        <div class="cursor-pointer hamburger-nav bg-gray-200 d-flex d-md-none">
          <div
            v-for="link in usePermissionsStore().subMenuRoutes"
            class="px-3"
          >
            <router-link
              @click="toggleMenuMobile"
              :class="'d-block justify-content-start nav-link text-nowrap w-100 text-center align-middle mb-0 py-2 h-100 text-secondary fw-semibold text-capitalize border-b nav-link position-relative'"
              :to="link.path"
            >
              <i
                :class="
                  'align-self-center d-block d-md-inline-block mx-auto text-secondary icon-w-5 icon-h-5 me-md-2 lkt-icon-' +
                  [link.meta.icon ? link.meta.icon : '']
                "
              ></i>
              <span class="text-xs">{{ $t("name." + link.name) }}</span>

            </router-link>
          </div>
          <i
            @click="toggleMenuMobile"
            :class="`ms-auto me-3 text-secondary text-3xl ${mobileMenuOpen ? 'bi-x-lg' : 'bi-list'}`"
          ></i>
        </div>
        <!--   MOBILE MENU   -->
        <div
          :class="`mobile-menu d-flex px-3 bg-gray-200 flex-column gap-2 ${mobileMenuOpen ? 'mobile-menu-open ' : ''}`"
        >
          <div
            v-for="link in usePermissionsStore().subMenuRoutes"
            class="bg-gray-100 rounded-3 px-3"
          >
            <router-link
              @click="toggleMenuMobile"
              :class="'d-flex justify-content-start nav-link text-nowrap w-100 text-center align-middle mb-0 py-2 h-100 text-secondary fw-semibold text-capitalize border-b nav-link position-relative'"
              :to="link.path"
            >
              {{ $t("name." + link.name) }}
            </router-link>
          </div>
        </div>
      </div>

      </div>
  </div>
</template>

<style scoped>
ul li {
  flex: 1 1 0;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
}

.active-link {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 3px;
  left: 0;
  background-color: #da1d52;
  bottom: -2px;
  transition: all 0.3s;
}

.mobile-menu {
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.mobile-menu-open {
  height: 90vh;
  transition: all 0.3s ease-in-out;
}

.navbar-sub {
  left: 0;
}

.navbar-sub-wrapper {
  height: 66px;
  width: 100vw;
  transition: all 0.5s;
}

@media screen and (min-width: 576px) {
  .navbar-sub {
  }

  .nav-link {
    font-size: 13px;
  }
}

@media screen and (min-width: 768px) {
  ul li {
    font-size: 14px;
  }

  .nav-link {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  ul li {
    font-size: 16px;
  }

  .nav-link {
    font-size: 18px;
  }
}

@media screen and (min-width: 1200px) {
  .nav-link {
    font-size: 22px;
  }
}
</style>
