import { useMockStore } from "@/store/Mock/MockStore";

export const initMockStore = () => {
  const mockStore = useMockStore(); // Accès au store après initialisation de Pinia

  if (!mockStore.$state.missionMock.length) {
    mockStore.setMissions();
  }

  if (!mockStore.$state.candidatureMock.length) {
    mockStore.setCandidatures();
  }

  if (!mockStore.$state.cabinetMock.length) {
    mockStore.setCabinet();
  }

  if (!mockStore.$state.cvMock.length) {
    mockStore.setCv();
  }

  if (!mockStore.$state.chatMock.length) {
    mockStore.setChat();
  }
};
