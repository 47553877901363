import { DirectiveBinding } from "vue";
import { usePermissionsStore } from "@/store/PermissionsStore";

declare global {
  interface HTMLElement {
    __vuePermissionComment?: Comment;
  }
}

function createCommentPlaceholder(el: HTMLElement): Comment {
  return document.createComment(`permission-hook`);
}

/**
 * Remove or inject html element according to user permissions
 * @param {HTMLElement} el
 * @param {DirectiveBinding: <Array>} binding
 * @returns {HTMLElement, string}
 */
function checkPermission(el: HTMLElement, binding: DirectiveBinding) {
  const { value } = binding;
  if (value && Array.isArray(value)) {
    if (value.length > 0) {
      const hasPermission = usePermissionsStore().hasPermission(value);

      if (!hasPermission) {
        // Remplace l'élément par un commentaire
        if (!el.__vuePermissionComment) {
          const comment = createCommentPlaceholder(el);
          el.__vuePermissionComment = comment; // Stocke le commentaire de remplacement dans l'élément
          el.parentNode?.replaceChild(comment, el);
        }
      } else {
        if (el.__vuePermissionComment) {
          el.__vuePermissionComment.parentNode?.replaceChild(
            el,
            el.__vuePermissionComment,
          );
          delete el.__vuePermissionComment;
        }
      }
    }
  } else {
    throw new Error(`need roles! Like v-permission="['admin','editor']"`);
  }
}

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    checkPermission(el, binding);
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    checkPermission(el, binding);
  },
};
