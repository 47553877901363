import { defineStore } from "pinia";
import { asyncRoutes, constantRoutes } from "@/router/routes";
import { RouteRecordRaw } from "vue-router";

export const usePermissionsStore = defineStore("permissionsStore", {
  state: () => {
    return {
      roles: [] as Array<string>,
      rights: [] as Array<string>,
      routes: [] as RouteRecordRaw[],
      authorizedRoutes: [] as Array<any>,
      subMenuRoutes: [] as Array<any>,
      navActive: 0 as Number,
    };
  },
  actions: {
    setRoles(roles: string[]) {
      this.$patch((state) => {
        state.roles = roles;
      });
    },

    setNavActive(index) {
      this.$patch((state) => {
        state.navActive = index;
      });
    },

    setRoutes() {
      this.$patch((state) => {
        // Only auth routes
        state.authorizedRoutes = this.hasRoutesPermission(
          asyncRoutes,
          this.roles,
        );
        // Only auth routes for nav
        state.subMenuRoutes = this.sortRoutes(
          state.authorizedRoutes.filter((route) => route.meta?.position),
        );
        // All public and auth routes
        state.routes = constantRoutes.concat(state.authorizedRoutes);
      });
    },

    hasPermission(rolesNeeded) {
      return rolesNeeded.some((role) =>
        this.roles.includes(role.toUpperCase()),
      );
    },

    // Retourne les routes sans meta.roles ou celles ayant occurence d'un des roles user
    hasRoutesPermission(routes, roles) {
      return routes.filter((route) => {
        const routeRoles = route.meta?.roles;
        return (
          !routeRoles ||
          roles.some((role) => routeRoles.includes(role.toUpperCase()))
        );
      });
    },

    sortRoutes(routes) {
      return routes.sort(
        (routeA, routeB) => routeA.meta?.index - routeB.meta?.index,
      );
    },
    clearRoles() {
      this.roles = [];
    },
    clearRoutes() {
      this.authorizedRoutes = [];
      this.routes = [];
    },
  },
});
