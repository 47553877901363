import { defineStore } from "pinia";
import { ChatMessage } from "@lkt/core";
import { Chat } from "@/entities/Chat";

interface State {
  Message: Chat[];
}

export const chatsStore = defineStore("ChatsStore", {
  state: (): State => {
    return {
      Message: [],
    };
  },
  actions: {
    setMessages(messages: Chat[]) {
      if (!messages || !messages.length) {
        return;
      }
      this.Message = messages;
    },
  },
  getters: {},
});
