interface Cv {
  idCandidature: number;
  fileName: string;
  format: string;
}

export const cvMock: any = [
  {
    1423807 : [
      {
        fileName: "moisant_cv.pdf",
        format: "pdf",
      },
      {
        fileName: "test doc 1.pdf",
        format: "pdf",
      },
      {
        fileName: "test doc 2.pdf",
        format: "pdf",
      }
    ]

  },
  {
    253807 : [
      {
        fileName: "moisant_cv.pdf",
        format: "pdf",
      },
      {
        fileName: "test doc 2.pdf",
        format: "pdf",
      }
    ]
  },
  {
    689807 : [
      {
        fileName: "test doc 1.pdf",
        format: "pdf",
      },
      {
        fileName: "test doc 2.pdf",
        format: "pdf",
      }
    ]
  },
];
