<script lang="ts">
import { Vue, Component } from "vue-facing-decorator";

interface Langs {
  locale: string,
  name: string,
  icon: string,
}

@Component({
})
export default class SelectLang extends Vue {
  langs: Langs[] = [
    {
      locale: "fr-FR",
      name: "Francais",
      icon: "fr.svg"
    },
    {
      locale: "en-US",
      name: "English US",
      icon: "us.svg"
    },
    {
      locale: "de-DE",
      name: "Deutsch",
      icon: "de.svg"
    },
    {
      locale: "es-ES",
      name: "Spain",
      icon: "es.svg"
    }
  ];

  selectFlagState: boolean = false;

  activeLang(index) {
    const elm = this.langs?.splice(index, 1)[0];
    this.$i18n.locale = elm?.locale;
    this.langs.splice(0, 0, elm);

  }

  toggleFlagSelect() {
    this.selectFlagState = !this.selectFlagState;
  }
}
</script>

<template>
  <ul @click="toggleFlagSelect" class="position-relative mb-0 ms-3">
    <li
      v-for="(lang, index) in langs"
      @click="toggleFlagSelect && index > 0 ? activeLang(index) : ''"
      :style="`${selectFlagState ? `top: ${index}00%;` : 'top:0%;'} z-index:${500 - index}`"
      :class="`position-absolute cursor-pointer p-1 rounded-3 bg-transparent `"
    >
      <img :src="'/'+lang.icon" :title="lang.name" :class="`mb-0 w-fit ${selectFlagState && index > 0 ? 'shadow' : ''}`" :alt="lang.name"/>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

ul {
  width: fit-content;
  height: 32px;
  li {
    width: 100%;
    z-index: 99999;
    list-style: none;
    transition: all 0.2s;
    left: 0;
    img {
      height: 18px;
      vertical-align: text-bottom;
    }
  }
  li:hover {
    background-color: $gray-200!important;
  }
}

@media (max-width: 768px) {
  li {
    background-color: darken($gray-200, 7%)!important;
  }
}
</style>
