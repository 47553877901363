export function transformFlatKeysToNested(obj: Record<string, string>) {
  const result: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    const keys = key.split(".");
    keys.reduce((acc, k, index) => {
      if (index === keys.length - 1) {
        acc[k] = obj[key];
      } else {
        if (!acc[k]) acc[k] = {};
      }
      return acc[k];
    }, result);
  });

  return result;
}
