<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-facing-decorator";

@Component({})
export default class PopOver extends Vue {
  @Prop({ type: Boolean, default: false })
  modalState!: boolean;
  @Prop({ required: false, type: String, default: ''})
  popinClass?: string;
  @Prop({required: false, type: String, default: '600'})
  popinWidth?: string;
  openModal() {
    this.modalState = true;
    this.disableScroll();
  }

  closeModal() {
    this.modalState = false;
    this.enableScroll();
  }

  toggleModal() {
    this.modalState = !this.modalState;
    if (this.modalState) {
      this.disableScroll();
    } else {
      this.enableScroll();
    }
  }

  disableScroll() {
    document.body.style.overflow = "hidden";
  }

  enableScroll() {
    document.body.style.overflow = "";
  }

  beforeUnmount() {
    this.enableScroll();
  }

  @Emit("toggle-modal-state")
  togglePopinState(state) {
    return state;
  }
}
</script>

<template>
  <div
    :class="`popin-wrapper ${modalState ? 'modal-open' : ''}`"
    @click.self="togglePopinState(false)"
  >
    <div :style="`width: ${popinWidth}px`" :class="`popin ${popinClass} ${modalState ? 'popin-open' : ''}`">
      <i class="bi bi-x close-icon" @click="togglePopinState(false)"></i>
      <slot> Aucun contenu ici </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.popin-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0);
  z-index: -1000;
  transition: all 0.2s ease-in-out;
}

.modal-open {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  transition: all 0.2s ease-in-out;
}

.popin {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  opacity: 0;
  top: -125%;
  transform: translate(0, -50%);
  transition:
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  width: 600px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;

  &.popin-open {
    opacity: 1;
    top: 50%;
  }
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: $gray-300;
}

@media (max-width: 649px) {
  .popin {
    top: 200px;
  }
}
</style>
