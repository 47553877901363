import { createApp } from "vue";
import { createPinia } from "pinia";
import { createBootstrap } from "bootstrap-vue-next";
import { formatDate, dateDiff } from "./services/filter/dateFilters";
import i18n from "@/i18n/i18n";
import Notifications from '@kyvg/vue3-notification'
import permission from "@/services/directive/permissions";
import settings from "@/settings";

import piniaPluginPersistedState from "pinia-plugin-persistedstate";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Plugin UTC requis pour timezone
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { initMockStore } from "@/store/Mock/initMockStore";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs().format("L LT");

// Exemple : Configuration du fuseau horaire par défaut (facultatif)
dayjs.tz.setDefault("Europe/Paris");

// import "@lkt/ui-html/dist/library/style.css";

// Import styles PrimeVue et app principale
import App from "./App.vue";

// Création app Vue
const app = createApp(App);

const pinia = createPinia();

pinia.use(piniaPluginPersistedState);
// Init pinia
app.use(pinia);

initMockStore();

// Bootstrap-next
//import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
app.use(createBootstrap());

app.use(Notifications, {componentName: "Notify"})

// Vue i18n
app.use(i18n);

// Traduction des enums depuis le BO
import EnumTranslatorVuePlugin from "@/plugins/EnumTranslatorVuePlugin"
app.use(EnumTranslatorVuePlugin)

// Import, config routeur
import router from "@/router/router";
import "@/router/permission"; // Import des interceptions de route
app.use(router);

// Config API
import { lktApi } from "@lkt/core";
lktApi.setConfig({ endpoint: settings.apiEndpoint });

import Tres from "@tresjs/core";
app.use(Tres);

// Custom directives
app.directive("permission", permission);

// Custom filters
app.config.globalProperties.$filters = {
  formatDate,
  dateDiff,
};

import "./styles/styles.scss";
// Montage app
app.mount("#app");
